import React, { useState, useEffect } from 'react'
import { Popup, Form, Input, TextArea, Toast, Button, Cascader, Dialog } from 'antd-mobile'
import { getAddress, updateAddress, deleteAddress, addAddress } from '../../../api'
import { regionData } from 'element-china-area-data-new2'
import css from './index.module.scss'
import { EditSOutline, AddOutline, RightOutline } from 'antd-mobile-icons'

const MineCascader = React.memo(({ value, onChange }) => {
    const [visible, setVisible] = useState(false)
    return (
        <>
            <div
                className={css.cascader}
                onClick={() => {
                    setVisible(true)
                }}
            >
                {value && value.length > 0 ? value.join('-') : <span style={{ color: '#ccc' }}>省市区,点击选择</span>}<RightOutline className={css.righticon} />
            </div>
            <Cascader
                options={regionData}
                value={value}
                onConfirm={(value) => { onChange(value) }}
                visible={visible}
                fieldNames={{ label: 'label', value: 'label', children: 'children' }}
                onClose={() => {
                    setVisible(false)
                }}
            >

            </Cascader>
        </>
    )
})

const Edit = React.memo(({ current, update, onClose }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue(current);
    }, [current])

    const confirmDelete = async () => {
        const result = await Dialog.confirm({
            content: '确认要删除该地址吗？',
        })
        if (result) {
            onDelete();
        }
    }
    const onDelete = async () => {
        try {
            const res = await deleteAddress({ id: current.id });
            if (res.code === 200) {
                Toast.show({ content: '删除成功', icon: 'success' });
                update();
                onClose();
            } else {
                throw new Error(res.message || '删除失败');
            }
        } catch (e) {
            console.log(e);
            Toast.show({ content: '删除失败', icon: 'fail' });
        }

    }
    const onFinish = async (values) => {
        const data = {
            ...values,
            province: values.ssq[0],
            city: values.ssq[1],
            region: values.ssq[2],
        }
        data.ssq = undefined;
        try {
            if (current.id) {
                data.id = current.id;
                const res = await updateAddress(data);
                if (res.code === 200) {
                    Toast.show({ content: '修改成功', icon: 'success' });
                    update();
                    onClose();
                } else {
                    throw new Error(res.message || '修改失败');
                }
            } else {
                const res = await addAddress(data);
                if (res.code === 200) {
                    Toast.show({ content: '添加成功', icon: 'success' });
                    update();
                    onClose();
                } else {
                    throw new Error(res.message || '添加失败');
                }
            }
        } catch (e) {
            console.log(e);
            Toast.show({ content: e.message || '操作失败', icon: 'fail' });
        }
    }

    return (
        <div className={css.container}>
            <div className={css.title}>{current.id ? '编辑' : '添加'}地址</div>
            <div className={`${css.delete} ${current.id ? '' : css.none}`} onClick={confirmDelete}>删除</div>
            <div className={css.box}>
                <Form
                    layout='horizontal'
                    form={form}
                    onFinish={onFinish}
                    footer={
                        <Button block type='submit' color='primary' size='large' style={{ '--background-color': '#006efd' }}>
                            提交
                        </Button>
                    }
                >
                    <Form.Header>地址信息</Form.Header>
                    <Form.Item
                        name='name'
                        label='姓名'
                        rules={[{ required: true, message: '姓名不能为空' }]}
                    >
                        <Input placeholder='请输入姓名' />
                    </Form.Item>
                    <Form.Item
                        name='phone'
                        label='手机号'
                        rules={[{ required: true, message: '手机号不能为空' }, { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确' }]}
                    >
                        <Input placeholder='请输入手机号' />
                    </Form.Item>
                    <Form.Item name='ssq' label='省市区' rules={[{ required: true, message: '省市区不能为空' }]}>
                        <MineCascader />
                    </Form.Item>
                    <Form.Item name='detailAddress' label='地址' help='详情地址'>
                        <TextArea
                            placeholder='请输入地址'
                            maxLength={100}
                            rows={2}
                            showCount
                        />
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
})

function Index({ visible, onClose }) {
    const [list, setList] = useState([]);
    const [current, setCurrent] = useState({});
    const [visible2, setVisible2] = useState(false);

    useEffect(() => {
        getList();
    }, [])

    const getList = async () => {
        const res = await getAddress();
        if (res.code === 200) {
            setList(res.data);
        } else {
            Toast.show({ content: res.message || '获取数据失败', icon: 'fail' });
        }
    }
    const onClose2 = () => {
        setCurrent({});
        setVisible2(false);
    }
    const onEdit = (item) => {
        const ssq = item.region ? [item.province, item.city, item.region] : [];
        setCurrent({ ...item, ssq });
        setVisible2(true);
    }
    const onAdd = () => {
        setCurrent({ ssq: [] });
        setVisible2(true);
    }

    return (
        <>
            <Popup
                position='right'
                visible={visible}
                showCloseButton
                destroyOnClose
                onClose={onClose}
            >
                <div className={css.container}>
                    <div className={css.title}>地址管理</div>
                    <div className={css.add} onClick={onAdd}><AddOutline />新增地址</div>
                    <div className={css.box}>
                        {list.map(item => (
                            <div className={css.item} key={item.id}>
                                <div className={css.left}>
                                    <div className={css.address}>{item.province + ' ' + item.city + ' ' + item.region}</div>
                                    <div className={css.detail}>{item.detailAddress}</div>
                                    <div className={css.detail}>{item.name + ' ' + item.phone}</div>
                                </div>
                                <EditSOutline className={css.right} onClick={() => onEdit(item)} />
                            </div>
                        ))}
                    </div>
                </div>
            </Popup>
            <Popup
                position='right'
                visible={visible2}
                showCloseButton
                destroyOnClose
                onClose={onClose2}>
                <Edit update={getList} current={current} onClose={onClose2} />
            </Popup>
        </>
    )
}

export default React.memo(Index)