import React, { useState, useEffect } from 'react';
import { Input } from 'antd-mobile';
import dayjs from 'dayjs';
import DatePicker from 'react-mobile-datepicker';
import { CloseCircleFill } from 'antd-mobile-icons';
import css from './comp.module.scss';

const config = {
    'year': {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
    'month': {
        format: 'M',
        caption: 'Mon',
        step: 1,
    },
    'date': {
        format: 'D',
        caption: 'Day',
        step: 1,
    },
    'hour': {
        format: 'hh',
        caption: 'Hour',
        step: 1,
    },
    'minute': {
        format: 'mm',
        caption: 'Min',
        step: 1,
    }
}


function TimeSelect(props) {
    const { value, onChange } = props;
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(true);
    }

    const handleCancel = () => {
        setIsOpen(false);
    }

    const handleSelect = (time) => {
        onChange(time);
        setIsOpen(false);
    }
    const clear = (e) => {
        e.stopPropagation();
        onChange(undefined);
    }

    return (
        <div onClick={handleClick}>
            <div className={css.timeflex}>
                <Input type="text" value={value ? dayjs(value).format('YYYY-MM-DD hh:mm') : ''} readOnly />
                {value ? <CloseCircleFill color='#999' onClick={clear} /> : null}
            </div>
            <DatePicker
                value={value}
                isOpen={isOpen}
                dateConfig={config}
                theme='default'
                headerFormat='YYYY-MM-DD hh:mm'
                min={props.min}
                max={props.max}
                onSelect={handleSelect}
                onCancel={handleCancel}
            />
        </div>
    )
}

export default React.memo(TimeSelect);