import css from './index.module.scss';
import React, { useState, useEffect } from 'react';
import { doPay, getInfo, getAddress } from '../../api/index';
import { Form as Mform, Button, Toast, SpinLoading } from 'antd-mobile';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeftOutline } from 'antd-mobile-icons';
import ProductWithStock from './comp/ProductWithStock.jsx';
import './quill.snow.css';
import { getDomByType } from './func.jsx';
import Big from 'big.js';
import Address from './comp/Address.jsx';
import { v4 } from 'uuid';

const ignoreList = ['remark'];

function App() {
    const [top, setTop] = useState({});
    const [listdata, setData] = useState([]);
    const [phoneForm] = Mform.useForm();
    const [pid, setId] = useState('');
    const [tempId, setTempId] = useState('');
    const [shopId, setShopId] = useState('');
    const [loading, setLoading] = useState(false);
    const [getloading, setGetLoading] = useState(false);
    const [selectedShops, setSelectedShops] = useState([]);
    const [deliveryList, setDeliveryList] = useState([]);
    const [addressList, setAddressList] = useState([]);
    const [pType, setPType] = useState(0);
    const [methods, setMethods] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setShopId(new URLSearchParams(location.search).get('shopId'));
        const id = new URLSearchParams(location.search).get('id');
        if (id) {
            getData(id);
        }
    }, [location.search]);
    const getData = async (id) => {
        setGetLoading(true);
        try {
            setId(id);
            const res = await getInfo({ id: id });
            if (res.code === 200) {
                setPType(res.data.projectType);
                const data = JSON.parse(res.data.templat.content);
                setTempId(res.data.templat.id);
                setTop({ title: res.data.templat.name, pic: data.topPic });
                setData(data.itemList.map((item) => {
                    if (item.type === 'timePicker') {
                        if (item.timerange) item.timerange = item.timerange.map(e => dayjs(e));
                    }
                    return item;
                }));
                if (res.data.projectType === 3) {
                    setSelectedShops(res.data.productList && Array.isArray(res.data.productList) ? res.data.productList.map(item => ({ ...item, num: 0 })) : []);
                    setDeliveryList(res.data.addressList);
                    setMethods(res.data.deliveryMethod.split(','));
                    getAddressList();
                }
            } else {
                Toast.show({ content: res.message || '获取数据失败', icon: 'fail' });
            }
        } catch (e) {
            console.log(e);
            Toast.show({ content: '获取数据失败', icon: 'fail' });
        } finally {
            setGetLoading(false);
        }
    }
    const getAddressList = async () => {
        try {
            const res = await getAddress();
            if (res.code === 200) {
                setAddressList(res.data);
            } else {
                throw new Error(res.message || '获取收货地址失败');
            }
        } catch (e) {
            Toast.show({ content: '获取收货地址失败', icon: 'fail' });
        }
    }
    const changeShopsNum = (id, num) => {
        setSelectedShops(selectedShops.map(item => {
            if (item.id === id) {
                item.num = num;
            }
            return item;
        }))
    }
    const validate = (val) => {
        const keys = Object.keys(val);
        for (let key of keys) {
            const item = listdata.find(e => +e.id === +key);
            if (!item) continue;
            if (item.required && !val[key]) {
                Toast.show({ content: item.title + '不得为空', icon: 'fail' });
                return false;
            }
        }
        return true;
    }

    const submit = async (val) => {
        if (!validate(val)) return;
        const keys = Object.keys(val);
        const dataList = [];
        let count = Big(0);
        for (let key of keys) {
            let temp = val[key];
            const item = listdata.find(e => +e.id === +key);
            if (!item) continue;
            if (item.type === 'pay') count = Big(count).plus(+temp);
            else if (item.type === 'product') {
                count = Big(count).plus(Big(+item.price).times(temp ?? 0));
                temp = { price: item.price, count: (temp ?? 0) };
            } else if (item.type === 'protocal') {
                if (!temp) return Toast.show({ content: '请先同意协议', icon: 'fail' });
                continue;
            } else if (ignoreList.includes(item.type)) {
                continue;
            }
            dataList.push({ title: item.title, value: temp, id: item.id });
        }
        if (!shopId) return Toast.show({ content: '缺少商户信息', icon: 'fail' });
        setLoading(true);
        const reqdata = {
            tempId: tempId,
            projectId: pid,
            shopId: shopId,
            content: JSON.stringify(dataList)
        }
        if (pType === 3) {
            reqdata.itemList = selectedShops.filter(item => item.num > 0).map(item => {
                count = Big(count).plus(+item.price * item.num);
                return { productId: item.id, productQuantity: item.num, productPrice: item.price, productName: item.name, productPic: item.pic }
            });
            reqdata.addressId = val.address[0];
            if (!reqdata.addressId) {
                setLoading(false);
                return Toast.show({ content: '必须选择一个提货点或收货地址！', icon: 'fail' });
            }
        }
        reqdata.amount = count.round(2);
        reqdata.onlyId = v4();
        const res = await doPay(reqdata);
        if (res.code && res.code !== 200) {
            Toast.show({ content: res.msg || '交易失败', icon: 'fail' });
            setLoading(false);
        } else if (res["qr_url"]) window.location.href = res["qr_url"];
        else {
            Toast.show({ content: res.msg || '交易失败', icon: 'fail' });
            setLoading(false);
        }
    }
    const goBack = () => {
        if (pType === 6) {
            window.history.go(-1);
            return false;
        }
        navigate('/home');
    }

    return (
        <div className={css.phone}>
            <div className={`${css.topbar} ${top.pic ? css.topbar2 : ''}`}>
            </div>
            <div className={css.titlebar}>
                <LeftOutline className={css.back} onClick={goBack} />
                <div className={`${css.title} `}>{top.title ? top.title : '标题'}</div>
            </div>
            <div className={`${css.imgbox} ${top.pic ? css.selectbox : ''}`}>
                {top.pic ? <img src={top.pic} alt="" /> : <></>}
            </div>
            <div className={`${css.itembox} ${top.pic ? css.hasPic : ''}`}>
                <Mform
                    layout={"horizontal"}
                    form={phoneForm}
                    name="phoneForm"
                    onFinish={submit}
                    onFinishFailed={(val) => {
                        console.log(val);
                    }}
                    style={{ '--border-inner': 'none', '--border-top': 'none', '--border-bottom': 'none', '--adm-color-background': '#f5f5f5', '--adm-color-box': '#ffffff' }}
                >
                    {pType === 3 ? selectedShops.map((item) => <ProductWithStock {...item} key={item.id} changeNum={changeShopsNum} />) : null}
                    {listdata.map((item) => getDomByType(item, phoneForm))}
                    {pType === 3 ? (
                        <Mform.Item name='address' style={{ '--padding-left': 0, '--padding-right': 0 }} rules={[{ required: true, message: '必须选择一个提货点或收货地址！' }]}>
                            <Address deliveryList={deliveryList} addressList={addressList} getList={getAddressList} methods={methods} />
                        </Mform.Item>) : null}
                    <div className={css.flexzone}>
                        <Button type="primary" htmlType="submit" className={css.bigbtn} loading={loading}>
                            提交
                        </Button>
                    </div>
                </Mform>
            </div>
            {getloading ? <div className={css.mask}>
                <SpinLoading style={{ '--size': '48px' }} color="#1296db"></SpinLoading>
            </div> : null}
        </div>
    );
}

export default React.memo(App);
