import fetch from "../utils/fetch";
import fetchWithoutToken from '../utils/fetchWithoutToken';

//---------------------------登录相关--------------------------
//登录
export function login(data) {
    return fetch({
        url: "/api/api/member/register",
        method: "post",
        params: data,
    });
}
//获取用户信息
export function getUserInfo() {
    return fetch({
        url: "/api/api/member/user",
        method: "get"
    });
}

//---------------------------修改用户信息----------------------
export function updateUserInfo(data) {
    return fetch({
        url: "/api/api/member/update",
        method: "post",
        params: data,
    });
}

//---------------------------获取代缴账单----------------------
export function getBill(data) {
    return fetch({
        url: "/api/sys/project/listByMember",
        method: "get",
        params: data,
    });
}

//---------------------------获取历史账单----------------------
export function getRecord(data) {
    return fetch({
        url: "/api/api/pay/orderList",
        method: "get",
        params: data,
    });
}

//---------------------------支付相关--------------------------
export function doPay(data) {
    return fetch({
        url: "/api/api/pay/singleePay",
        method: "post",
        data: data,
    });
}
export function doPay2(data) {
    return fetchWithoutToken({
        url: "/api/api/pay/singleePay",
        method: "post",
        data: data,
    });
}
export function getInfo(data) {
    return fetch({
        url: "/api/api/project/h5ProjectInfo",
        method: 'get',
        params: data,
    });
}
export function getSignUpInfo(data) {
    return fetchWithoutToken({
        url: "/api/api/project/h5ProjectInfo/singUp",
        method: 'get',
        params: data,
    });
}
export function getInfo2(data) {
    return fetchWithoutToken({
        url: "/api/sys/project/h5ProjectInfo",
        method: 'get',
        params: data,
    });
}
export function refundApply(data) {
    return fetch({
        url: "/api/sms/refund/applyOrderRefund",
        method: 'post',
        params: data,
    });
}

//---------------------------下载--------------------------
export function download(data) {
    return fetch({
        url: "/api/file/download",
        method: "get",
        params: data,
        type: 'blob'
    });
}
//---------------------------收货地址相关-------------------
export function updateAddress(data) {
    return fetch({
        url: "/api/api/address/update",
        method: "put",
        data: data
    });
}
export function getAddress(data) {
    return fetch({
        url: "/api/api/address/list",
        method: "get",
        params: data
    });
}
export function deleteAddress(data) {
    return fetch({
        url: "/api/api/address/remove",
        method: "delete",
        params: data
    });
}
export function addAddress(data) {
    return fetch({
        url: "/api/api/address/save",
        method: "post",
        data: data
    });
}
/*************************人群管理*************************/
//获取信息
export function getGroupInfo(data) {
    return fetch({
        url: "/api/api/smsGroupUser/simpleInfo",
        method: "get",
        params: data,
    });
}
//提交信息
export function AddtoGroup(data) {
    return fetch({
        url: "/api/api/smsSingleUser/saveUserProperH5",
        method: "post",
        data: data,
    });
}