import React, { useState, useEffect } from 'react';
import css from './comp.module.scss';
// import { v4 as uuid } from 'uuid';
import { Radio, Picker } from 'antd-mobile';
import { RightOutline, FillinOutline } from 'antd-mobile-icons'
import Address from '../../component/Address';

const AddressItem = React.memo(({ item }) => {
    return (
        <span key={item.id}>{item.name + ' ' + item.phone + ' ' + item.province + item.city + item.region + ' ' + item.detailAddress ?? ''}</span>
    )
})


function Index({ value, onChange, deliveryList, addressList, getList, methods }) {
    const [mode, setMode] = useState(methods && methods.length ? methods[0] : undefined);
    const [current, setCurrent] = useState(null);
    const [visible, setVisible] = useState(false);
    const [dlist, setDlist] = useState([]);
    const [alist, setAlist] = useState([]);
    const [visible2, setVisible2] = useState(false);

    useEffect(() => {
        setDlist(deliveryList ? [deliveryList.map(e => ({ value: e.id, label: e.detailAddress }))] : []);
    }, []);

    useEffect(() => {
        setAlist(addressList ? [addressList.map(e => ({ ...e, value: e.id }))] : []);
    }, [addressList]);

    useEffect(() => {
        setCurrent(null);
        if (value) {
            const idx1 = deliveryList && Array.isArray(deliveryList) ? deliveryList.findIndex(e => +e.id === +value) : -1;
            if (idx1 > -1) {
                setCurrent(deliveryList[idx1]);
            } else {
                const idx2 = addressList.findIndex(e => +e.id === +value);
                if (idx2 > -1) {
                    setCurrent(addressList[idx2]);
                }
            }
        }
    }, [value, deliveryList, addressList]);

    const openAddress = () => {
        setVisible2(true);
    }

    const closeAddress = () => {
        getList();
        setVisible2(false);
    }

    return (
        <div className={css.address}>
            <div className={css.address_title}>配送方式</div>
            <Radio.Group value={mode} onChange={(val) => {
                setMode(val);
                setCurrent(null);
                onChange(undefined);
            }}>
                {methods.includes('1') ? <Radio value="1">自提</Radio> : null}
                {methods.includes('2') ? <Radio value="2">配送</Radio> : null}
            </Radio.Group>
            <div className={css.address_title2}>{mode === '1' ? '选择提货点' : '选择收货地址'}</div>
            <div
                onClick={() => {
                    setVisible(true)
                }}
                className={`${css.flexwithCenter}`}
            >
                <div className={css.address_left}>
                    {current ? <div className={css.address_item}>
                        {mode === '1' ? current.detailAddress : <AddressItem item={current} />}
                    </div> : <span className={css.gray}>点击选择</span>}
                </div>
                <RightOutline className={css.rightIcon} />
            </div>
            {mode === '2' ? <div style={{ color: '#006efd', padding: '10px 0' }} onClick={openAddress}>管理地址<FillinOutline /></div> : null}
            <Picker
                columns={mode === '1' ? dlist : alist}
                visible={visible}
                renderLabel={v => mode === '1' ? v.label : <AddressItem item={v} />}
                onClose={() => {
                    setVisible(false)
                }}
                value={value}
                onConfirm={v => {
                    onChange(v);
                }}
            />
            <Address visible={visible2} onClose={closeAddress} />
        </div>
    )
}

export default React.memo(Index);