//写一个移动端登录页面，函数组件，带去注册
import React, { useState, useEffect } from 'react';
import { SpinLoading, Button, Toast } from 'antd-mobile';
import { useNavigate, useLocation } from 'react-router-dom';
import css from './index.module.scss';
import { getSignUpInfo } from '../../api';
import { EnvironmentOutline, ClockCircleOutline } from 'antd-mobile-icons';
import { useSelector } from "react-redux";

function Index() {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state) => state.userInfo);
    const [loading, setLoading] = useState(false);
    const [getloading, setGetLoading] = useState(false);
    const [thedata, setData] = useState({});
    const [shopId, setShopId] = useState(undefined);
    const [decoId, setId] = useState(undefined);

    useEffect(() => {
        setShopId(new URLSearchParams(location.search).get('shopId'));
        const id = new URLSearchParams(location.search).get('id');
        if (id) {
            getData(id);
        }
    }, [location.search]);
    const getData = async (id) => {
        setGetLoading(true);
        try {
            setId(id);
            const res = await getSignUpInfo({ id: id });
            if (res.code === 200) {
                setData(res.data);
            } else {
                Toast.show({ content: res.message || '获取数据失败', icon: 'fail' });
            }
        } catch (e) {
            console.log(e);
            Toast.show({ content: '获取数据失败', icon: 'fail' });
        } finally {
            setGetLoading(false);
        }
    }

    const goDeco = async () => {
        if (!decoId) {
            Toast.show('缺少收款单信息！');
            return;
        }
        navigate(`/deco?id=${decoId}&shopId=${shopId}`);
    }

    return (
        <div className={css.container}>
            <div className={css.notes} dangerouslySetInnerHTML={{ __html: thedata.notes }}></div>
            <div className={css.box}>
                <div className={css.title}>活动时间</div>
                <div className={css.text}><ClockCircleOutline />{thedata.activityTime}</div>
            </div>
            <div className={css.box}>
                <div className={css.title}>活动地点</div>
                <div className={css.text}><EnvironmentOutline />{thedata.activityAddress}</div>
            </div>
            <div className={css.guest}>
                {(thedata.guestList ?? []).map((item, index) => {
                    return (
                        <div key={index} className={css.gitem}>
                            <div className={css.gleft}>
                                <div className={css.gleft_img}>
                                    {item.profilePicture ? <img src={item.profilePicture} className={css.gleft_imgpic} alt='嘉宾图片' /> :
                                        <div className={css.placeholder}></div>}
                                </div>
                                <div className={css.gleft_txt}>{item.name}</div>
                            </div>
                            <div className={css.gright}>
                                <div className={css.gleft_txt}>职业：{item.job}</div>
                                <div>介绍：{item.introduce}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={css.bot}>
                <div className={css.progressbox}>
                    <div className={css.progress}>
                        <div className={css.shadow} style={{ width: ((thedata.payCount ?? 0) * 100 / thedata.payLimit).toFixed(2) + '%' }}></div>
                    </div>
                    <div className={css.progress_text}>{(thedata.payCount ?? 0) + '/' + thedata.payLimit}</div>
                </div>
                <div className={css.btn} onClick={goDeco}>立即报名</div>
            </div>
        </div>
    );
}

export default React.memo(Index);