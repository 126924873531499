/* 
    可配置多级路由，添加权限修改Layout中的createMenu方法，
    createMenu方法会将router数组第一个对象渲染到侧边栏，其它路由隐藏，如登录、打印、404页面
*/
import { lazy } from "react";
import Login from "../Pages/Login/login";
import LoginMerchant from "../Pages/LoginMerchant/login";
import Register from "../Pages/Register";
import Deco from "../Pages/Deco/deco";
import Normal from "../Pages/Deco/normal";
import Home from "../Pages/Home";
import ProtocalDeatil from "../Pages/Deco/comp/ProtocalDetail";
import OrderInfo from "../Pages/OrderInfo";
import Addtag from "../Pages/Addtag";
import Enroll from "../Pages/Enroll/index";
// import Mine from "../Pages/Mine";
import NotFound from '../Pages/NotFound';
import { Navigate } from "react-router-dom";

// const ProtocalDeatil = lazy(() => import("../Pages/Deco/comp/ProtocalDetail"));
// const OrderInfo = lazy(() => import("../Pages/OrderInfo"));

const router = [
  { path: "/login", element: <Login /> },
  { path: "/loginMerchant", element: <LoginMerchant /> },
  { path: "/register", element: <Register /> },
  { path: "/deco", element: <Deco /> },
  { path: "/normal", element: <Normal /> },
  { path: "/home", element: <Home /> },
  { path: "/protocal", element: <ProtocalDeatil /> },
  { path: "/orderInfo", element: <OrderInfo /> },
  { path: "/addtag", element: <Addtag /> },
  { path: "/enroll", element: <Enroll /> },
  // { path: "/mine", element: <Mine /> },
  // { path: '/404', element: <NotFound /> },
  { path: "*", element: <Navigate to="/home" /> },
];

export default router;
