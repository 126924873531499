import React, { useState, useEffect } from 'react';
import { Input } from 'antd-mobile';
import dayjs from 'dayjs';
import DatePicker from 'react-mobile-datepicker';
import { CloseCircleFill } from 'antd-mobile-icons';
import css from './comp.module.scss';

const styleObj = { display: 'inline', marginLeft: '10px' };

const config = {
    'year': {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
    'month': {
        format: 'M',
        caption: 'Mon',
        step: 1,
    },
    'date': {
        format: 'D',
        caption: 'Day',
        step: 1,
    },
    'hour': {
        format: 'hh',
        caption: 'Hour',
        step: 1,
    },
    'minute': {
        format: 'mm',
        caption: 'Min',
        step: 1,
    }
}


function TimeSelect(props) {
    const { value, onChange } = props;
    const [startTime, setStartTime] = useState(value ? dayjs(value[0]) : undefined);
    const [endTime, setEndTime] = useState(value ? dayjs(value[1]) : undefined);

    const [isStartOpen, setIsStartOpen] = useState(false);
    const [isEndOpen, setIsEndOpen] = useState(false);

    const handleClick = () => {
        setIsStartOpen(true);
    }

    const handleCancel = () => {
        setStartTime(undefined);
        setIsStartOpen(false);
    }

    const handleCancel2 = () => {
        setStartTime(undefined);
        setEndTime(undefined);
        setIsEndOpen(false);
    }

    const handleSelect = (time) => {
        setStartTime(time);
        setIsStartOpen(false);
        const timer = setTimeout(() => {
            setIsEndOpen(true);
            timer && clearTimeout(timer);
        }, 100);
    }

    const handleSelect2 = (time) => {
        setEndTime(time);
        setIsEndOpen(false);
        onChange([dayjs(startTime).format('YYYY-MM-DD hh:mm'), dayjs(time).format('YYYY-MM-DD hh:mm')]);
    }

    const clear = (e) => {
        e.stopPropagation();
        onChange(undefined);
        setStartTime(undefined);
        setEndTime(undefined);
    }

    return (
        <div onClick={handleClick}>
            {/* <div className={css.timeflex}>
                <Input type="text" value={startTime ? dayjs(startTime).format('YYYY-MM-DD hh:mm') : ''} readOnly placeholder='开始时间' />
                {startTime ? <CloseCircleFill color='#999' onClick={clear} /> : null} 至
            </div>
            <div className={css.timeflex}>
                <Input type="text" value={endTime ? dayjs(endTime).format('YYYY-MM-DD hh:mm') : ''} readOnly placeholder='结束时间' />
                {endTime ? <CloseCircleFill color='#999' onClick={clear} /> : null}
            </div> */}
            <div className={`${css.timebox} ${css.timeflex}`}>
                <div>{startTime ? dayjs(startTime).format('YYYY-MM-DD hh:mm') + ' 至 ' : ''}{endTime ? dayjs(endTime).format('YYYY-MM-DD hh:mm') : ''}</div>
                {startTime ? <CloseCircleFill color='#999' onClick={clear} fontSize={20} /> : <div className={css.defaultTime}>请选择时间</div>}
            </div>

            <DatePicker
                value={startTime}
                isOpen={isStartOpen}
                dateConfig={config}
                theme='default'
                headerFormat='开始时间：YYYY-MM-DD hh:mm'
                onSelect={handleSelect}
                onCancel={handleCancel}
                confirmText='下一步'
            />
            <DatePicker
                value={endTime}
                isOpen={isEndOpen}
                dateConfig={config}
                theme='default'
                headerFormat='结束时间：YYYY-MM-DD hh:mm'
                onSelect={handleSelect2}
                onCancel={handleCancel2}
                confirmText='完成'
            />
        </div>
    )
}

export default React.memo(TimeSelect);