//写一个移动端登录页面，函数组件，带去注册
import React, { useState, useEffect } from 'react';
import { SpinLoading, Button, Toast } from 'antd-mobile';
import { useNavigate, useLocation } from 'react-router-dom';
import css from './index.module.scss';
import { getGroupInfo, AddtoGroup } from '../../api';
import { LeftOutline } from 'antd-mobile-icons';
import { useSelector } from "react-redux";

function Index() {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state) => state.userInfo);
    const [loading, setLoading] = useState(false);
    const [getloading, setGetLoading] = useState(false);
    const [tag, setTag] = useState(null);
    const [thedata, setData] = useState({});

    useEffect(() => {
        const tag = new URLSearchParams(location.search).get('tag');
        if (tag) {
            setTag(tag);
            getTagInfo(tag);
        }
    }, []);

    const goBack = () => {
        navigate('/home');
    }

    const getTagInfo = async (id) => {
        setGetLoading(true);
        try {
            const result = await getGroupInfo({ id });
            if (result.code === 200) {
                setData(result.data);
            } else {
                throw new Error(result.message ?? '获取信息失败');
            }
        } catch (err) {
            Toast.show('获取信息失败');
        } finally {
            setGetLoading(false);
        }
    }
    const submit = async () => {
        if (!tag) {
            Toast.show('缺少组织信息！');
            return;
        }
        setLoading(true);
        try {
            const reqdata = { groupIds: '' + tag, phone: user.user.phone, realName: user.user.nickname, userId: thedata.userId };
            const result = await AddtoGroup(reqdata);
            if (result.code === 200) {
                Toast.show('加入成功');
                navigate('/home');
            } else {
                throw new Error(result.message ?? '加入失败');
            }
            navigate('/home');
        } catch (err) {
            Toast.show('加入失败');
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className={css.container}>
            <div className={css.titlebar}>
                <LeftOutline className={css.back} onClick={goBack} />
                <div className={`${css.title} `}>返回</div>
            </div>
            <div className={css.iconbox}>
                <div className={css.icon}></div>
            </div>
            <div className={css.mid}>是否加入<span>{thedata.merName ?? '-'}</span>的组织<span>{thedata.groupName ?? '-'}</span>？</div>
            <Button className={css.btn} loading={loading} onClick={submit}>确认</Button>
            {getloading ? <div className={css.mask}>
                <SpinLoading style={{ '--size': '48px' }} color="#1296db"></SpinLoading>
            </div> : null}
        </div>
    );
}

export default React.memo(Index);