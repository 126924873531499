//写一个移动端登录页面，函数组件，带去注册
import React, { useState } from 'react';
import { Button, Input, Toast } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { UserAddOutline } from "antd-mobile-icons";
import css from './index.module.scss';
import { login } from '../../api';
import { useDispatch } from 'react-redux';
import { getInfoByToken } from '../../store/features/userInfoSlice';

function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [repassword, setRePassword] = useState('');

    const validate = () => {
        if (!username) {
            Toast.show('请输入用户名');
            return false;
        }
        if (!password) {
            Toast.show('请输入密码');
            return false;
        }
        if (repassword !== password) {
            Toast.show('两次密码不一致');
            return false;
        }
        if (!phone || !/^1[3456789]\d{9}$/.test(phone)) {
            Toast.show('请输入正确的手机号');
            return false;
        }
        return true;
    }

    const handleLogin = async () => {
        if (!validate()) {
            return;
        }
        setLoading(true);
        try {
            const reqdata = { nickname: username, password, flag: 0, phone };
            const openId = localStorage.getItem('openId');
            if (openId) reqdata.openId = openId;
            const result = await login(reqdata);
            if (result.code === 200) {
                Toast.show('注册成功');
                dispatch(getInfoByToken());
                const redirect = localStorage.getItem('redirect_path');
                if (redirect) {
                    localStorage.removeItem('redirect_path');
                    navigate(redirect);
                } else {
                    navigate('/home');
                }
            } else {
                Toast.show(result.message ?? '注册失败');
            }
        } catch (err) {
            Toast.show('注册失败');
        } finally {
            setLoading(false);
        }
    };

    const goLogin = () => {
        navigate('/login');
    }

    return (
        <div className={css.container}>
            <div className={css.login}>
                {/* <div>{<UserAddOutline className={css.icon} />}</div> */}
                <div className={css.ttitle}>欢迎使用<span>新利轻松收</span></div>
                <Input
                    className={css.input}
                    placeholder="请输入用户名"
                    value={username}
                    onChange={setUsername}
                ></Input>
                <Input
                    className={css.input}
                    placeholder="请输入密码"
                    type="password"
                    value={password}
                    onChange={setPassword}
                    maxLength={16}
                ></Input>
                <Input
                    className={css.input}
                    placeholder="确认密码"
                    type="password"
                    value={repassword}
                    onChange={setRePassword}
                ></Input>
                <Input
                    className={css.input}
                    placeholder="请输入手机号"
                    value={phone}
                    onChange={setPhone}
                    maxLength={11}
                ></Input>
                <Button type="primary" onClick={handleLogin} loading={loading} className={css.loginbtn}>注册</Button>
                {<span onClick={goLogin} className={css.register}>去登录</span>}
            </div>
        </div>
    );
}

export default React.memo(Login);