import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import css from './comp.module.scss';

const currentTime = dayjs();
const thisyear = currentTime.format('YYYY');
const days = [currentTime.format('MM-DD'), currentTime.add(1, 'day').format('MM-DD'), currentTime.add(2, 'day').format('MM-DD'), currentTime.add(3, 'day').format('MM-DD'), currentTime.add(4, 'day').format('MM-DD')];
const options = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'];

function TimeSelect(props) {
    const { onChange } = props;
    const [day, setDay] = useState(0);
    const [dayList, setDayList] = useState(days);
    const [hour, setHour] = useState(undefined);
    const [hourList, setHourList] = useState([]);
    const scrollel = useRef(null);

    useEffect(() => {
        setHour(undefined);
        setHourList(options.filter(e => dayjs(thisyear + '-' + dayList[day] + ' ' + e).isAfter(currentTime)));
        setHour(0);
        if (scrollel) scrollel.current.scrollLeft = 0;
    }, [day]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        onChange(thisyear + '-' + dayList[day] + ' ' + hourList[hour]);
    }, [hour]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={css.timeSelect}>
            <div className={css.dayzone}>
                {dayList.map((e, i) => <div key={i} className={`${css.day} ${day === i ? css.dayselected : ''}`} onClick={() => setDay(i)}>{e}</div>)}
            </div>
            <div className={css.dayzone} ref={scrollel}>
                {hourList.map((e, i) => <div key={i} className={`${css.day} ${hour === i ? css.dayselected : ''}`} onClick={() => setHour(i)}>{e}</div>)}
            </div>
        </div>
    )
}

export default React.memo(TimeSelect);