import css from './index.module.scss';
import React from 'react';
import { Form as Mform, Input, Selector, Button, Toast, SpinLoading } from 'antd-mobile';
import Timeselect from './comp/Time.jsx';
import Timerange from './comp/TimeRange.jsx';
import TimeSelect2 from './comp/TimeSelect.jsx';
import Product from './comp/Product';
import Protocal from './comp/Protocal';
import './quill.snow.css';

const defaultTimeStart = new Date(1970, 0, 1);
const defaultTimeEnd = new Date(2099, 11, 31);

export const getDomByType = (item, phoneForm) => {
    switch (item.type) {
        case 'text':
            return (
                <div className={`${css.item}`} key={item.id}>
                    <div className={`${css.label} ${item.required ? css.require : ''}`}>{item.title}</div>
                    <Mform.Item name={item.id} key={item.id} className={css.input} rules={item.required ? [{ required: true, message: '该项不得为空' }] : []}>
                        <Input type="text" placeholder={item.placeholder ? item.placeholder : ''} maxLength={item.maxlength ? item.maxlength : undefined} />
                    </Mform.Item>
                </div>
            )
        case 'pay':
            if (item.count) phoneForm.setFieldValue(item.id, item.count);
            return (
                <div className={`${css.item}`} key={item.id}>
                    <div className={`${css.label} ${css.require}`}>{item.title}</div>
                    <Mform.Item name={item.id} key={item.id} className={css.input} rules={[{ required: true, message: '该项不得为空' }]}>
                        <Input type="text" placeholder='输入金额' value={item.count ? item.count : undefined} disabled={item.count ? true : false} />
                    </Mform.Item>
                </div>
            )
        case 'check':
            return (
                <div className={`${css.item} ${css.flex}`} key={item.id}>
                    <div className={`${css.label} ${item.required ? css.require : ''}`}>{item.title}</div>
                    <Mform.Item name={item.id} key={item.id} className={css.input} style={{ '--padding-left': 0 }} rules={[{ required: item.required ? true : false, message: '该项不得为空' }, item.most ? { type: 'array', max: item.most, message: '选择选项超出限制' } : undefined]}>
                        <Selector
                            columns={item.col ?? 2}
                            multiple={item.multiple}
                            options={item.choose ?? []}
                        />
                    </Mform.Item>
                </div>
            )
        case 'timePicker':
            return (
                <div className={`${css.item}`} key={item.id}>
                    <div className={`${css.label} ${item.required ? css.require : ''}`}>{item.title}</div>
                    <Mform.Item name={item.id} key={item.id} className={css.input} rules={item.required ? [{ required: true, message: '该项不得为空' }] : []}>
                        <Timeselect min={item.timerange ? item.timerange[0].toDate() : defaultTimeStart} max={item.timerange ? item.timerange[1].toDate() : defaultTimeEnd} />
                    </Mform.Item>
                </div>
            )
        case 'timeSe':
            return (
                <div className={`${css.item}`} key={item.id}>
                    <div className={`${css.label} ${item.required ? css.require : ''}`}>{item.title}</div>
                    <Mform.Item name={item.id} key={item.id} className={css.input} rules={item.required ? [{ required: true, message: '该项不得为空' }] : []}>
                        <Timerange />
                    </Mform.Item>
                </div>
            )
        case 'timeSelect':
            return (
                <div className={`${css.item} ${css.item2}`} key={item.id}>
                    <div className={`${css.label} ${item.required ? css.require : ''}`}>{item.title}</div>
                    <Mform.Item name={item.id} key={item.id} className={css.input} rules={item.required ? [{ required: true, message: '该项不得为空' }] : []}>
                        <TimeSelect2 />
                    </Mform.Item>
                </div>
            )
        case 'remark':
            phoneForm.setFieldValue(item.id, item.count);
            return (
                <div className={`${css.item}`} key={item.id} style={{ padding: 0 }}>
                    <Mform.Item name={item.id} key={item.id} className={css.input}>
                        <div
                            className={`${css.content} ql-editor`}
                            dangerouslySetInnerHTML={{ __html: item.remark }}
                        ></div>
                    </Mform.Item>
                </div>
            )
        case 'product':
            return (
                <div className={`${css.item}`} key={item.id}>
                    <Mform.Item name={item.id} key={item.id} className={css.input} style={{ '--padding-left': 0, '--padding-right': 0 }}>
                        <Product {...item} />
                    </Mform.Item>
                </div>
            )
        case 'protocal':
            return (
                <div className={`${css.item} `} key={item.id} >
                    <Mform.Item name={item.id} key={item.id} className={css.input} style={{ '--padding-left': 0 }}>
                        <Protocal {...item} />
                    </Mform.Item>
                </div>
            )
        default:
            return <div key={item.id}></div>;
    }
}